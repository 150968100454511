import { reaction, makeAutoObservable } from 'mobx';
import { t } from 'i18next';
import {
  Attachment,
  ConsentStatus,
  IAttachment,
  IExternalAttachment,
  IStagedAttachment,
  IUtterance,
  Medium,
  Status,
  Utterance,
  UploadAttachmentResponse,
} from 'src/gen/squareup/messenger/v3/messenger_service';
import type MessengerController from 'src/MessengerController';
import Api from 'src/api/Api';
import {
  createUtteranceClientId,
  failLocalUtterance,
  hasConsentToTypeMessage,
  isAuthError,
} from 'src/utils/transcriptUtils';
import Transcript from 'src/stores/objects/Transcript';
import { getGoogleReviewUrl } from 'src/utils/url';
import Logger from 'src/Logger';
import {
  TranscriptMessengerPage,
  CouponMetadata,
  LocalFile,
  LocalUtterance,
  Photo,
  HighlightSegment,
} from 'src/MessengerTypes';
import { KEY_MESSAGES_PLUS } from 'src/stores/FeatureFlagStore';

/**
 * Store that contains state used throughout the transcript view UI components.
 * Not intended to store data associated with an individual transcript, but rather
 * state pertaining to the UI itself.
 *
 * What belongs here:
 * - State required across multiple UI components under the TranscriptViewPage
 * - State that is specific to the view itself, and not data associated with a transcript
 * - State that is only intended to be read/written from within the view page hierarchy
 * What doesn't belong here:
 * - State only used internally in a single component --> can be kept in local component state
 * - State that needs to be accessed/updated across multiple pages
 * - Data pertaining to an individual transcript --> can be contained on the transcript object itself
 */
class TranscriptViewStore {
  private _stores: MessengerController;
  private _api: Api;

  /**
   * The local state of the message input bar on the transcript view.
   */
  message = '';

  /**
   * State describing if the message input can be focused. Typically, this is when
   * the page transition is done. This is to resolve a visual bug where when we
   * navigate into this page, the contents of the page jitters instead of doing a
   * smooth transition as expected. This is because the textarea in MessageInput was
   * calling autoFocus, which causes the browser to force scroll the element into the viewport.
   * While it is doing that, MessengerContent is also doing a transition animation,
   * causing a visual conflict. This fix allows the transition to end first before
   * telling the textarea to focus.
   */
  canFocusInput = false;

  /**
   * Photo being viewed in the Photo Gallery.
   *
   * If this photo is a local photo, it will be updated once it is "staged" so that we can
   * match it to the server-returned photo by its `hash` property.
   */
  selectedPhoto?: Photo;

  /**
   * Email subject for outgoing merchant messages.
   * This is only populated if the user has modified the email subject.
   */
  private _customizedEmailSubject?: string;

  /**
   * Used to compute clock skew: this is the max spoken at timestamp of any utterance we're
   * tracking. Therefore, the current timestamp must always be greater than this timestamp.
   * Primarily used as a failsafe for setting a pending utterance's spoken at timestamp.
   */
  private _latestUtteranceTimestamp = 0;

  constructor(stores: MessengerController) {
    makeAutoObservable(this);

    this._stores = stores;
    this._api = stores.api;

    // Clear the local UI state each time the selected transcript changes
    reaction(
      () => this.TranscriptMessengerPage?.transcriptId,
      () => this.reset(),
    );
  }

  reset = (): void => {
    this.message = '';
    this._customizedEmailSubject = undefined;
    this.canFocusInput = false;
  };

  setMessage = (message: string): void => {
    this.message = message;
  };

  onTransitionEnd = (): void => {
    this.canFocusInput = true;
  };

  setSelectedPhoto = (photo?: Photo): void => {
    this.selectedPhoto = photo;
  };

  setCustomizedEmailSubject = (newEmailSubject?: string): void => {
    this._customizedEmailSubject = newEmailSubject;
  };

  // Function to call when requesting review. If the selected seller key
  // has a Google place ID set up, set the message to the review link. Else
  // we open the settings page.
  requestReview = (): void => {
    const { navigation, user, modal, event, settings } = this._stores;
    const { unitSettings } = settings;

    const unitSetting = unitSettings.get(this.transcript.sellerKey);
    if (unitSetting) {
      if (unitSetting.url || unitSetting.placeId) {
        this.setMessage(
          `${
            unitSetting.reviewMessage ||
            t('ReviewSetupModal.default_message', {
              businessName: user.businessName,
            })
          } ${unitSetting.url || getGoogleReviewUrl(unitSetting.placeId)}`,
        );
      } else {
        navigation.openSheet('SETTINGS');
        modal.openReviewSetupModal(unitSetting);
        event.track('View Review Setup Modal', {
          unit_token: this.transcript.sellerKey,
        });
      }
    } else {
      Logger.logWithSentry(
        'Unable to find unit setting while requesting review',
        'error',
        {
          unitSettings,
          sellerKey: this.transcript.sellerKey,
        },
      );
      navigation.openSheet('SETTINGS');
    }
  };

  // Called when some action has been taken where the user wants to open the 'send coupon' modal
  // May not be able to open coupon modal if consent needs to be requested (in which case the
  // request consent modal is opened instead). Example usages include the Coupon Suggested Action
  // or the Coupon option in the Actions menu
  openCouponModal = (metadata?: CouponMetadata): void => {
    const { navigation, modal } = this._stores;

    if (this.isNotSubscribedToMPlus) {
      navigation.openSheet('MESSAGES_PLUS_PRICING');
      return;
    }

    if (
      this.transcript.consentStatus !== ConsentStatus.GRANTED_MARKETING &&
      this.transcript.medium === Medium.SMS
    ) {
      modal.openRequestConsentModal({
        transcriptId: this.transcript.id,
        medium: this.transcript.medium,
        sellerKey: this.transcript.sellerKey,
        consentStatus: this.transcript.consentStatus,
      });
    } else {
      modal.openCouponModal(metadata);
    }
  };

  // Displays a modal asking the user to confirm they have consent to message this customer.
  confirmConsent = async (): Promise<boolean> => {
    // Only time we need to confirm consent is when consent status is DENIED_BY_DEFAULT
    if (this.transcript.consentStatus === ConsentStatus.DENIED_BY_DEFAULT) {
      await new Promise((resolve, reject) => {
        // Opens the modal which will resolve this promise if the user confirms
        this._stores.modal.openConfirmConsentModal(
          resolve as () => void,
          reject,
        );
      });
      return true;
    }
    return false;
  };

  sendMessage = async ({
    message,
    metadata = {},
    localFiles = [],
    attachments = [],
    isConsentConfirmed = false,
    externalAttachments = [],
  }: {
    message: string;
    metadata?: Utterance.IMetadata;
    localFiles?: LocalFile[];
    attachments?: readonly IAttachment[];
    isConsentConfirmed?: boolean;
    externalAttachments?: IExternalAttachment[];
  }): Promise<void> => {
    const { user, event, status, modal, featureFlag } = this._stores;
    // Get transcript object at time of sendMessage call, and use this reference across the send message lifecycle to
    // prevent the possibility of the transcript changing across the action lifecycle.
    const transcript: Transcript = this.transcript;
    const {
      id: transcriptId,
      utterances,
      consentStatus,
      medium,
      sellerKey,
      customerToken,
      localUtterances,
      hasNextPage,
    } = transcript;
    const {
      clientId: utteranceClientId,
      coupon,
      checkoutLink,
      emailSubject = this.emailSubject,
    } = metadata;

    // Skip sending email subject if not customized. BE will apply the default subject `{unit} sent you a message`
    // BE will apply a different default email subject for coupons `{merchant} sent you a coupon`
    const formattedEmailSubject =
      coupon || emailSubject === this.defaultEmailSubject
        ? undefined
        : emailSubject;

    const clientId = utteranceClientId || createUtteranceClientId(transcriptId);
    const utterance: IUtterance = {
      sendStatus:
        localFiles.length > 0
          ? Utterance.SendStatus.UPLOADING
          : Utterance.SendStatus.PENDING,
      speakerType: Utterance.SpeakerType.MERCHANT,
      spokenAtMillis: this._getCurrentTime(utterances),
      plainText: message,
      metadata: {
        clientId,
        coupon,
        checkoutLink,
        emailSubject: formattedEmailSubject,
      },
      attachments,
    };
    const messageDestination = {
      transcriptId,
      medium,
      sellerKey,
      consentStatus,
    };
    const photos = localFiles.filter(
      (file) => file.type === Attachment.AttachmentType.IMAGE,
    );
    const files = localFiles.filter(
      (file) => file.type === Attachment.AttachmentType.FILE,
    );
    const localUtterance: LocalUtterance = {
      utterance,
      messageDestination,
      photos: photos.map((file) => ({
        url: file.url,
        isLocal: true,
      })),
      files: files.map((file) => ({
        name: file.file.name,
        mimeType: file.file.type,
        sizeBytes: file.file.size,
        url: file.url,
      })),
      externalAttachments,
    };

    // Since the user may not be at the bottom of the transcript when sending a new message, if the transcript has another page to load, we should clear the existing utterances
    // to reset the view to the most recent page of utterances, so we can scroll to the newly sent message
    if (hasNextPage) {
      transcript.clearUtterances();
      transcript.load();
    }

    // Adds the local utterance to the transcript state and persists it in IndexedDB
    transcript.addLocalUtterance(localUtterance);

    const mediumFlags = featureFlag.getMediumFlags(medium);
    let stagedAttachments: IStagedAttachment[] = [];
    try {
      // Upload all local files before proceeding
      stagedAttachments = await Promise.all([
        ...photos.map((file) =>
          this._api.file.uploadPhoto({
            localPhoto: file,
            sellerKey,
            medium,
            attachmentSizeLimit: Math.min(
              mediumFlags.attachmentMaxChunkSizeBytes,
              mediumFlags.maxPhotoSizeBytes,
            ),
          }),
        ),
        ...files.map((file) =>
          this._api.file.upload({
            blob: file.file,
            sellerKey,
            medium,
            type: Attachment.AttachmentType.FILE,
            name: file.file.name,
          }),
        ),
      ]);
    } catch (error) {
      Logger.logWithSentry(
        'TranscriptViewStore:sendMessage - Error uploading local files/photos',
        'error',
        { error, localFiles, transcriptId },
      );

      // Special error handling for these errors
      if (error instanceof UploadAttachmentResponse) {
        if (error.status?.code === Status.Code.ATTACHMENT_TYPECHECK_FAILED) {
          await failLocalUtterance(
            transcript,
            localUtterance,
            Utterance.SendStatus.UNDELIVERABLE,
          );
          modal.openModal('FILE_UNSUPPORTED_FORMAT');
          return;
        }

        if (error.status?.code === Status.Code.ATTACHMENT_VIRUS_FAILED) {
          await failLocalUtterance(
            transcript,
            localUtterance,
            Utterance.SendStatus.UNDELIVERABLE,
          );
          modal.openModal('FILE_VIRUS_DETECTED');
          return;
        }
      }

      // Generic error handling
      status.setError({
        label: t('TranscriptViewPage.error_uploading'),
      });
      await failLocalUtterance(transcript, localUtterance);
      return;
    }

    if (stagedAttachments.length > 0) {
      await transcript.updateLocalUtterance({
        ...localUtterance,
        photos: localUtterance.photos?.map((localPhoto, index) => {
          return {
            ...localPhoto,
            hash: stagedAttachments[index]?.hash,
          };
        }),
      });

      // If a local photo is selected in Photo Gallery, update the selected photo with its `hash`.
      if (
        this.selectedPhoto?.isLocal &&
        this.selectedPhoto.hash === undefined
      ) {
        const { isLocal, url } = this.selectedPhoto;

        for (const utterance of localUtterances) {
          const { photos } = utterance;
          if (!photos || !photos.length) return;

          const matching = photos.findIndex(
            (photo) => isLocal === photo.isLocal && url === photo.url,
          );
          if (matching !== undefined && matching !== -1) {
            this.selectedPhoto = photos[matching];
            // Don't need to keep iterating through rest of local utterances
            break;
          }
        }
      }
    }

    Logger.log(
      `Sending message '${message}' to transcript ${transcriptId} with id ${clientId}`,
    );

    const { statusCode, utteranceId } = await this._api.messaging.sendMessage({
      id: transcriptId,
      utterance,
      confirmedConsent: isConsentConfirmed,
      stagedAttachments,
      externalAttachments,
    });

    // Only log the 'Send Message' event if the utterance ID is present
    // Note this is only guaranteed to be present when the response status is SUCCESS or CONSENT_DENIED
    if (utteranceId) {
      event.track('Send Message', {
        transcript_id: transcriptId,
        customer_token: customerToken,
        medium: Medium[medium].toLowerCase(),
        merchant_token: user.merchantToken,
        sender: 'merchant',
        unit_token: sellerKey,
        checkout_link_id: checkoutLink?.id,
        attachment_count: stagedAttachments.length || attachments?.length,
        utterance_id: utteranceId,
        email_subject: formattedEmailSubject,
        is_email_subject_default:
          medium === Medium.EMAIL
            ? emailSubject === this.defaultEmailSubject
            : undefined,
      });
    }

    switch (statusCode) {
      case Status.Code.SUCCESS:
        // If sending succeeds, do nothing
        return;
      case Status.Code.RATE_LIMITED:
        // If the user sent too many messages in the 24h time frame, show a modal
        // to prevent spam and immediately fail the local utterance.
        modal.openSendLimitModal();
        await failLocalUtterance(transcript, localUtterance);
        return;
      case Status.Code.BLOCKED_BY_SELLER:
        // If the response failed due to the merchant blocking the customer
        // remove the attempted utterance and show a modal explaining this.
        await transcript.removeLocalUtterance(clientId);
        event.track('View Message Not Sent', {
          merchant_token: user.merchantToken,
          transcript_id: transcriptId,
        });
        modal.openMessageBlockedModal();
        return;
      case Status.Code.UNVERIFIED:
        // If the user sent too many messages in the 24h time frame, show a
        // modal to prevent spam and immediately fail the local utterance.
        modal.openUnverifiedModal();
        await failLocalUtterance(transcript, localUtterance);
        return;
      case Status.Code.CONSENT_DENIED_BY_AI:
        if (!utteranceId) {
          Logger.logWithSentry(
            'TranscriptViewStore:sendMessage - Send Message Response with status DENIED_BY_AI unexpectedly did not return an utterance ID.',
            'error',
            {
              statusCode,
              utteranceId,
            },
          );
        }
        modal.openRequestConsentModal(messageDestination, utteranceId);
        return;
      case Status.Code.PAN_DETECTED:
        await failLocalUtterance(transcript, localUtterance);
        return;
      default:
        // If the message failed to send for another reason, fail the local
        // utterance and show an error.
        status.setError({
          label: t('TranscriptViewPage.error_sending'),
        });
        await failLocalUtterance(transcript, localUtterance);
    }
  };

  // There is the possibility of clock skew between client and server. To account
  // for this, we check if any of the latest utterances from the server are more recent
  // than the client's Date.now() value. If yes, we use a future time from that latest
  // utterance timestamp.
  private _getCurrentTime = (utterances: IUtterance[]): number => {
    utterances.forEach((utterance: IUtterance) => {
      if (
        utterance.spokenAtMillis != null &&
        utterance.spokenAtMillis > this._latestUtteranceTimestamp
      ) {
        this._latestUtteranceTimestamp = utterance.spokenAtMillis;
      }
    });

    let now = Date.now();
    if (this._latestUtteranceTimestamp > now) {
      Logger.warn(
        `Detected clock skew of > ${this._latestUtteranceTimestamp - now}ms`,
      );
      now = this._latestUtteranceTimestamp + 1; // Don't go backwards in time
    }
    return now;
  };

  markTranscriptAsReadIfUnread = async (): Promise<void> => {
    try {
      if (!this.transcript.isRead && !this.transcript.hasNextPage) {
        await this.transcript.markAsRead();
      }
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          `TranscriptViewStore:markTranscriptAsRead - An error occurred when attempting to mark transcript with ID ${this.transcript.id} as read`,
          'error',
          { error },
        );
      }
    }
  };

  markTranscriptAsUnread = async (): Promise<void> => {
    try {
      await this.transcript.markAsUnread();
      this._stores.status.setSuccess({
        label: t('TranscriptViewPage.more_menu.mark_unread.success_message'),
      });
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          `TranscriptViewStore:markTranscriptAsUnread - An error occurred when attempting to mark transcript with ID ${this.transcript.id} as unread`,
          'error',
          { error },
        );
      }
      this._stores.status.setError();
    }
  };

  blockTranscript = async (): Promise<void> => {
    try {
      await this.transcript.block();
      this._stores.status.setSuccess({
        label: t('ConfirmBlockModal.success_message'),
      });
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          `TranscriptViewStore:blockTranscript - An error occurred when attempting to block transcript with ID ${this.transcript.id}`,
          'error',
          { error },
        );
      }
      this._stores.status.setError({
        label: t('ConfirmBlockModal.error_message'),
      });
    }
  };

  unblockTranscript = async (): Promise<void> => {
    try {
      await this.transcript.unblock();
      this._stores.status.setSuccess({
        label: t('TranscriptViewPage.more_menu.unblock.success_message'),
      });
    } catch (error) {
      if (!isAuthError(error)) {
        Logger.logWithSentry(
          `TranscriptViewStore:unblockTranscript - An error occurred when attempting to unblock transcript with ID ${this.transcript.id}`,
          'error',
          { error },
        );
      }
      this._stores.status.setError({
        label: t('TranscriptViewPage.more_menu.unblock.error_message'),
      });
    }
  };

  // Provides a shorthand to get the current Transcript object to use for the transcript view UI components
  get transcript(): Transcript {
    // Error handling for missing selectedTranscriptId is contained in useTranscriptErrorHandling
    return this._stores.transcripts.get(
      this.TranscriptMessengerPage?.transcriptId || 0,
    );
  }

  get isLoading(): boolean {
    return (
      this.transcript.status === 'LOADING' ||
      !this.TranscriptMessengerPage?.transcriptId
    );
  }

  get title(): string {
    if (this.transcript.title) {
      return this.transcript.title;
    } else if (this.isLoading) {
      // Hide the 'Unknown User' title while the transcript data is still loading
      return '';
    }
    return t('TranscriptViewPage.unknown_user');
  }

  get TranscriptMessengerPage(): TranscriptMessengerPage {
    return this._stores.navigation.navStoreForUrl
      .currentPage as TranscriptMessengerPage;
  }

  /**
   * Checks if the current transcript is unable to send a message due to a missing M+ subscription.
   */
  get isMissingSubscription(): boolean {
    return Boolean(
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
        this.transcript.consentStatus ===
          ConsentStatus.DENIED_SUBSCRIPTION_REQUIRED,
    );
  }

  /**
   * Return true if the merchant is subscribed to M+ and failed TFN verification but can try again.
   */
  get isFailedRetryable(): boolean {
    return Boolean(
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
        this._stores.subscription.isUnitRetryableFailure(
          this.transcript.sellerKey,
        ),
    );
  }

  /**
   * Return true if the merchant is pending TFN verification under the M+ V2 onboarding flow.
   */
  get isPendingVerification(): boolean {
    return Boolean(
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
        this._stores.subscription.isUnitPendingVerification(
          this.transcript.sellerKey,
        ),
    );
  }

  /**
   * Return true if the transcript's unit is pending TFN verification, failed retryable, or failed
   * nonretryable (but not yet prohibited). These would all qualify for showing the pending
   * verification empty state in the M+ V2 onboarding flow.
   */
  get isPendingOrFailedRetryable(): boolean {
    return Boolean(
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
        this._stores.subscription.isUnitPendingOrFailedRetryable(
          this.transcript.sellerKey,
        ),
    );
  }

  /**
   * Return true if the transcript's unit is failed nonretryable (but not yet prohibited).
   * This would qualify for showing the pending verification state in the M+ V2 onboarding flow.
   */
  get isFailedNonretryableAndNotProhibited(): boolean {
    return Boolean(
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
        this._stores.subscription.isUnitFailedNonretryableAndNotProhibited(
          this.transcript.sellerKey,
        ),
    );
  }

  /**
   * Return true if the transcript's unit is pending TFN verification, or failed nonretryable
   * (but not yet prohibited). This would qualify for showing the pending verification state
   * in the M+ V2 onboarding flow.
   */
  get isPendingOrFailedNonretryableNotProhibited(): boolean {
    return (
      this.isPendingVerification || this.isFailedNonretryableAndNotProhibited
    );
  }

  /**
   * Indicates if the transcript view inputs should be disabled (i.e. input bar, actions menu, etc.).
   * Occurs when the merchant does not have consent to type a message or if the transcript is blocked.
   */
  get isInputDisabled(): boolean {
    return (
      !hasConsentToTypeMessage(this.transcript.consentStatus) ||
      Boolean(this.transcript.isBlocked) ||
      this.isUnitInactive
    );
  }

  /**
   * Indicates if this transcript does not fall under an M+ subscription (or pending subscription),
   * in which case access to certain features will be restricted.
   */
  get isNotSubscribedToMPlus(): boolean {
    return Boolean(
      this._stores.featureFlag.get(KEY_MESSAGES_PLUS) &&
        !this._stores.subscription.isUnitSubscribed(
          this.transcript.sellerKey,
        ) &&
        !this.isPendingOrFailedRetryable,
    );
  }

  /**
   * Indicates if this transcript is subscribed to M+.
   */
  get isSubscribedToMPlus(): boolean {
    return Boolean(
      this._stores.subscription.isUnitSubscribed(this.transcript.sellerKey),
    );
  }

  /**
   * Returns email subject for the outgoing message, if transcript medium is EMAIL.
   * If the user has customized the email subject, that is returned.
   * If not, it checks whether or not the last utterance has an email subject
   * and returns that if it's available.
   * If not, and there is a unit name, it will default to "[Unit Name] sent you a message".
   *
   * If an empty string or undefined are returned, upon sending the message, the backend
   * will override the email subject with a default value.
   */
  get emailSubject(): string | undefined {
    if (this.transcript.medium !== Medium.EMAIL) return undefined;

    if (this._customizedEmailSubject !== undefined)
      return this._customizedEmailSubject;

    // See if the last utterance in the transcript has an email subject
    const lastViewItem =
      this.transcript.viewItems[this.transcript.viewItems.length - 1];
    if (
      (
        (lastViewItem?.attachedUtterance ||
          lastViewItem?.data) as LocalUtterance
      )?.utterance?.metadata?.emailSubject
    ) {
      return (
        (lastViewItem.attachedUtterance || lastViewItem.data) as LocalUtterance
      ).utterance.metadata?.emailSubject;
    }

    // If there's no customized email subject and no email subject for last utterance,
    // default to "[Unit Name] sent you a message".
    return this.defaultEmailSubject;
  }

  /**
   * Returns the default email subject "[Unit Name] sent you a message".
   *
   * This is used if the last utterance does not contain an email subject and if the
   * merchant did not change the email subject.
   */
  get defaultEmailSubject(): string | undefined {
    const unitName = this._stores.user.units
      .get(this.transcript.sellerKey)
      ?.businessName?.trim();

    return (
      unitName &&
      t('MessageInput.emailSubject.default', {
        unitName,
      })
    );
  }

  /**
   * Returns the highlight segments for the seeked utterance, if it exists.
   *
   * @returns {HighlightSegment[]} - Array of start and end offsets for each highlight segment
   */
  get seekedUtteranceHighlightSegments(): HighlightSegment[] {
    if (this.transcript.seekUtteranceId) {
      const searchResult = this._stores.searchV2.utterances.search.results.find(
        (result) => result.utterance.id === this.transcript.seekUtteranceId,
      );
      return (
        searchResult?.highlightSegments?.map((segment) => [
          segment.startOffset as number,
          segment.endOffset as number,
        ]) || []
      );
    }
    return [];
  }

  /**
   * Retrieves the utterance associated with the most recent messages plugin entry.
   * Used for the Messages Plugin launch banner, as the banner should only be shown
   * on the most recent messages plugin entry. Note this the most recent messages plugin entry
   * that has been loaded client-side, not necessarily the most recent utterance in the transcript.
   */
  get mostRecentMessagesPluginEntryUtterance(): IUtterance | undefined {
    for (let i = this.transcript.viewItems.length - 1; i >= 0; i--) {
      const viewItem = this.transcript.viewItems[i];
      if (
        viewItem.dataType === 'MESSAGES_PLUGIN_SUBMISSION' &&
        viewItem.componentType === 'CUSTOMER_EVENT_CARD'
      ) {
        return viewItem.attachedUtterance?.utterance;
      }
    }
    return undefined;
  }

  get isUnitInactive(): boolean {
    return !this._stores.user.units.get(this.transcript.sellerKey)?.isActive;
  }

  get hasUtterances(): boolean {
    return this.transcript.viewItems.length > 0;
  }
}

export default TranscriptViewStore;
