import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MessengerSettings } from 'src/gen/squareup/messenger/v3/messenger_service';
import {
  MarketButton,
  MarketDivider,
  MarketList,
  MarketRow,
  MarketSelect,
} from 'src/components/Market';
import AppointmentIcon from 'src/svgs/AppointmentIcon';
import {
  getBookingChannelsUrl,
  getBuyerBookingFlowUrl,
  getSquareOnlineUrl,
} from 'src/utils/url';
import { SHADOW_REACT_ROOT_SELECTOR } from 'src/utils/shadowDomUtils';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './MessagesPluginSettings.scss';
import { KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED } from 'src/stores/FeatureFlagStore';
import GlobeIcon from 'src/svgs/GlobeIcon';
import WebsiteSettingRow from 'src/components/WebsiteSettingRow/WebsiteSettingRow';
import useSheetSectionScrollTo from 'src/hooks/useSheetSectionScrollTo';

/**
 * Renders the UI for the Messages Plugin settings on the Settings Page.
 */
const MessagesPluginSettings = observer((): ReactElement => {
  const { user, featureFlag, settings, event, navigation } =
    useMessengerControllerContext();
  const { t } = useTranslation();

  const {
    pluginEnabledOnAppts,
    pluginResponseTime,
    userPluginEnabledOnAppts,
    userPluginResponseTime,
    setUserPluginEnabledOnAppts,
    setUserPluginResponseTime,
    sqOnlineSettings,
    setUserPluginEnabledOnSqOnlineSite,
    hasSqOnlineSettings,
    canViewSqOnlineSettings,
    canEnableOnlineBookingSite,
    canEnablePluginOnAppts,
    userPluginEnabledOnSqOnlineSite,
  } = settings;

  const headerRef = useSheetSectionScrollTo('MESSAGES_PLUGIN');

  const hasSingleSqOnlineSite =
    hasSqOnlineSettings && sqOnlineSettings.length === 1;

  const onUserPluginEnabledOnApptsChanged = (enabled: boolean): void => {
    setUserPluginEnabledOnAppts(enabled);
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface: 'APPOINTMENTS_BOOKING_SITE',
    });
  };

  const onClickEnableOnlineBooking = (): void => {
    event.track('Click Enable Online Booking from Text Us');
  };

  const onUserPluginEnabledOnSqOnlineSite = (enabled: boolean): void => {
    event.track('Click Text Us Toggle', {
      enabled,
      setting_source: 'messages_settings',
      surface: 'SQUARE_ONLINE_SITE',
    });
    setUserPluginEnabledOnSqOnlineSite(sqOnlineSettings[0], enabled);
  };

  return (
    <div>
      <h3 className="MessagesPluginSettings__title" ref={headerRef}>
        {t('MessagesPluginSettings.title')}
      </h3>
      <p className="paragraph-30 MessagesPluginSettings__description">
        {t('MessagesPluginSettings.description')}
      </p>
      <img
        src="https://conversations-production-f.squarecdn.com/resources/inbox-settings-plugin.png"
        alt={t('MessagesPluginSettings.image_alt_text')}
        className="MessagesPluginSettings__image"
      />
      {((featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
        canEnableOnlineBookingSite) ||
        canEnablePluginOnAppts ||
        canViewSqOnlineSettings) && (
        <>
          <h5 className="MessagesPluginSettings__surfaces-title">
            {t('MessagesPluginSettings.surfaces.title')}
          </h5>
          {featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
            canEnableOnlineBookingSite && (
              <WebsiteSettingRow
                icon={<AppointmentIcon />}
                websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
                description={t(
                  'MessagesPluginSettings.enable_online_booking_description',
                )}
                url={getBookingChannelsUrl(featureFlag.useAppSubdomain)}
                urlLabel={t(
                  'MessagesPluginSettings.enable_online_booking_button_label',
                )}
                urlOnClick={onClickEnableOnlineBooking}
                toggleDisabled
                dataTestId="MessagesPluginSettings__enable-online-booking-row"
              />
            )}
          {canEnablePluginOnAppts && (
            <WebsiteSettingRow
              selected={
                userPluginEnabledOnAppts ?? pluginEnabledOnAppts.boolValue
              }
              onMarketRowSelected={() =>
                onUserPluginEnabledOnApptsChanged(true)
              }
              onMarketRowDeselected={() =>
                onUserPluginEnabledOnApptsChanged(false)
              }
              dataTestId="MessagesPluginSettings__appts-row"
              icon={<AppointmentIcon />}
              websiteLabel={t('MessagesPluginSettings.surfaces.appts_title')}
              description={t(
                'MessagesPluginSettings.surfaces.appts_description',
              )}
              url={getBuyerBookingFlowUrl(
                user.merchantToken,
                featureFlag.useAppSubdomain,
              )}
            />
          )}
          {canViewSqOnlineSettings && (
            <WebsiteSettingRow
              selected={
                hasSingleSqOnlineSite &&
                (userPluginEnabledOnSqOnlineSite?.pluginEnabled?.boolValue ??
                  sqOnlineSettings[0].pluginEnabled?.boolValue)
              }
              onMarketRowSelected={() => {
                if (hasSingleSqOnlineSite) {
                  onUserPluginEnabledOnSqOnlineSite(true);
                } else {
                  event.track('Click Into Text Us Multiple Websites');
                  navigation.sheet.navigateTo('SQ_ONLINE_SETTINGS');
                }
              }}
              onMarketRowDeselected={() => {
                if (!hasSingleSqOnlineSite) return;
                onUserPluginEnabledOnSqOnlineSite(false);
              }}
              icon={<GlobeIcon />}
              websiteLabel={
                hasSingleSqOnlineSite
                  ? t('MessagesPluginSettings.surfaces.sq_online_title_single')
                  : t(
                      'MessagesPluginSettings.surfaces.sq_online_title_multiple',
                    )
              }
              description={t(
                'MessagesPluginSettings.surfaces.sq_online_description',
              )}
              url={
                hasSingleSqOnlineSite && sqOnlineSettings[0].url
                  ? getSquareOnlineUrl(sqOnlineSettings[0].url)
                  : undefined
              }
              variant={hasSingleSqOnlineSite ? 'regular' : 'drill'}
              dataTestId="MessagesPluginSettings__sq-online-row"
            />
          )}
          <MarketDivider margin="small" size="thin" />
          <h5>{t('MessagesPluginSettings.customize_title')}</h5>
          <MarketSelect
            popoverContainer={SHADOW_REACT_ROOT_SELECTOR}
            value={`${
              userPluginResponseTime ??
              pluginResponseTime.pluginResponseTimeValue
            }`}
            onMarketSelectValueDidChange={(e) => {
              const newResponseTimeValue = Number.parseInt(
                e.detail.value as string,
                10,
              );
              setUserPluginResponseTime(newResponseTimeValue);
              event.track('Update Response Time', {
                response_time:
                  MessengerSettings.PluginResponseTime[newResponseTimeValue],
              });
            }}
            data-testid="MessagesPluginSettings__response-time-select"
          >
            <label>{t('MessagesPluginSettings.response_time.title')}</label>
            <MarketList slot="list">
              <MarketRow value={`${MessengerSettings.PluginResponseTime.ASAP}`}>
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.asap')}
                </label>
              </MarketRow>
              <MarketRow
                value={`${MessengerSettings.PluginResponseTime.FEW_HOURS}`}
              >
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.few_hours')}
                </label>
              </MarketRow>
              <MarketRow
                value={`${MessengerSettings.PluginResponseTime.WITHIN_DAY}`}
              >
                <label slot="label">
                  {t('MessagesPluginSettings.response_time.within_day')}
                </label>
              </MarketRow>
            </MarketList>
          </MarketSelect>
        </>
      )}
      {!featureFlag.get(KEY_SQ_ONLINE_PLUGIN_SETTING_ENABLED) &&
        canEnableOnlineBookingSite && (
          <MarketButton
            rank="primary"
            className="MessagesPluginSettings__enable-online-booking-button"
            href={getBookingChannelsUrl(featureFlag.useAppSubdomain)}
            target="_blank"
            onClick={onClickEnableOnlineBooking}
            onKeyDown={(e) => onKeyDownEnter(e, onClickEnableOnlineBooking)}
            data-testid="MessagesPluginSettings__enable-online-booking-button"
          >
            {t('MessagesPluginSettings.enable_online_booking_button_label')}
          </MarketButton>
        )}
    </div>
  );
});

export default MessagesPluginSettings;
