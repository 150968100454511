import { CDP } from '@squareup/cdp';
import Logger from 'src/Logger';
import type MessengerController from 'src/MessengerController';
import { CDP_PRODUCTION_API_KEY, CDP_STAGING_API_KEY } from './sharedKeys';
import { Environment } from 'src/MessengerTypes';

/**
 * The names of the events to track. Should follow those listed in the tracking plan:
 * https://docs.google.com/spreadsheets/d/1dPLRO4sl3W6m1F41bftmVKV3ueMN2khh6J1xUKCH-5M/edit?ts=5f694813#gid=1026500899
 */
export type TrackingName =
  | 'Send Message'
  | 'Click Messages Icon'
  | 'Open Conversations List'
  | 'Click New Message'
  | 'Click Search Contact'
  | 'Click Search'
  | 'Open Conversation'
  | 'Click Conversations Menu'
  | 'Click View Profile'
  | 'View Next Appointment'
  | 'Click Assistant'
  | 'Click Assistant Search'
  | 'Click Assistant Link'
  | 'View Looks Like Marketing Modal'
  | 'Click Looks Like Marketing Modal Request Consent'
  | 'Click Looks Like Marketing Modal Dismiss'
  | 'Click Message Input Request Consent'
  | 'View Send Coupon Modal'
  | 'Click Send Coupon Modal Send Coupon'
  | 'Click Send Coupon Modal Dismiss'
  | 'View New Customer Modal'
  | 'Click New Customer Modal Save'
  | 'Click New Customer Modal Dismiss'
  | 'View Confirm Consent Modal'
  | 'Click Confirm Consent Modal Confirm'
  | 'Click Confirm Consent Modal Dismiss'
  | 'Click Request Consent Feedback'
  | 'View Send Limit Modal'
  | 'Click Send Limit Modal Dismiss'
  | 'Click View Transaction Receipt'
  | 'Click View Transaction Feedback'
  | 'Click View Campaign'
  | 'Click View Coupon'
  | 'Click View Appointment Past'
  | 'Click View Appointment Next'
  | 'Click View Order'
  | 'Click View Invoice'
  | 'Click View Estimate'
  | 'View Send Checkout Link Modal'
  | 'Click Checkout Link Modal Send'
  | 'Click Checkout Link Modal Dismiss'
  | 'Click View Payment Link'
  | 'Click Medium Selector'
  | 'View Smart Reply'
  | 'Click Smart Reply'
  | 'Use SDK getConversationPreview'
  | 'Use SDK sendMessage'
  | 'Use SDK checkConsent'
  | 'Click Do Not Disturb Toggle'
  | 'Click Do Not Disturb Modal Confirm'
  | 'Click Do Not Disturb Modal Dismiss'
  | 'Click Block'
  | 'Click Confirm Block'
  | 'Click Unblock'
  | 'View Message Not Sent'
  | 'Click Add Contact Info'
  | 'View Change Medium Modal'
  | 'Click Change Medium Modal Save'
  | 'Click Change Medium Modal Dismiss'
  | 'View Change Location Modal'
  | 'Click Change Location Modal Save'
  | 'Click Change Location Modal Dismiss'
  | 'View IDV Modal'
  | 'Click IDV Modal Verify Identity'
  | 'Click IDV Modal Dismiss'
  | 'View Messages via URL Query String Parameter'
  | 'View Review Setup Modal'
  | 'Click Review Setup Modal Preview Link'
  | 'Click Review Setup Modal Done'
  | 'Click Review Setup Modal Dismiss'
  | 'View Review Setup Modal Empty Business Name'
  | 'Click Review Setup Modal Business Prediction'
  | 'View Review Setup Modal No Business Predictions'
  | 'Click Review Setup Modal Clear'
  | 'Edit Review Setup Modal Default Message'
  | 'Click Review Setup Modal Unlink'
  | 'Click Review Setup Modal Update Your Google Business Profile Link'
  | 'View Conversations List Null State'
  | 'Click Conversations List Null State Send Message'
  | 'Click Settings Page Save'
  | 'View Reviews Tooltip'
  | 'Click Reviews Tooltip Dismiss'
  | 'View Full Page Inbox'
  | 'Click Open Full Page Inbox'
  | 'View Smart Action'
  | 'Click Smart Action'
  | 'Receive Notification'
  | 'Filter Notification'
  | 'View Full Page Inbox Launch Tooltip'
  | 'Click Full Page Inbox Launch Tooltip Dismiss'
  | 'Click Search Gif'
  | 'Click Gif modal dismiss'
  | 'Send Gif'
  | 'Click Customer Detail Banner'
  | 'Click View Details Menu Option'
  | 'Click Open Customer Blade'
  | 'Click Close Customer Blade'
  | 'Click Customer Details Contact ID'
  | 'Click Merge Customer Button'
  | 'Click Buyer Summary Details'
  | 'Click Conversation in Conversation History'
  | 'Click Customer in Merge Customers View'
  | 'Click Voicemail Action'
  | 'Click View Photo Gallery'
  | 'Click Photo in Transcript View'
  | 'Click Update Payment'
  | 'Click Resubscribe'
  | 'View Subscription Pricing'
  | 'Click Subscription Pricing Action'
  | 'View Settings'
  | 'Click Settings Number Action'
  | 'Click Settings Get Numbers Back'
  | 'Click Manage Subscription'
  | 'Click Messages Plus Subscribe'
  | 'View CBD Null State'
  | 'Click CBD Learn More'
  | 'Click Create Action'
  | 'View Units with Missing Information'
  | 'Click Units with Missing Information'
  | 'View Unit Verification Form'
  | 'Submit Unit Verification Form'
  | 'Click Verification Failed Banner'
  | 'View Payment Alternatives Modal'
  | 'Click New Customer Banner Add to Directory'
  | 'Click Record Voicemail Greeting'
  | 'Click Record New Voicemail Greeting Button'
  | 'Save Voicemail Greeting Settings'
  | 'Click Play Voicemail Greeting'
  | 'Click Edit Voicemail Greeting'
  | 'Click Enable Voicemail Toggle'
  | 'View Confirm Payment Method'
  | 'Click Confirm Payment Method'
  | 'View Choose the Locations'
  | 'Click Choose the Locations'
  | 'View Successful Verification Submission'
  | 'Click Successful Verification Submission'
  | 'View Verification Failed Banner'
  | 'Click Verification In Progress Page'
  | 'View Verification In Progress Banner'
  | 'Click Verification In Progress Banner'
  | 'View Verification Result'
  | 'Click Verification Result'
  | 'View Successfully Verified Banner'
  | 'Click Successfully Verified Banner'
  | 'View Verification Failed Retryable Banner'
  | 'Click Verification Failed Retryable Banner'
  | 'Click Settings Review Resubmit'
  | 'Start Outbound Merchant Message'
  | 'Complete Search Session'
  | 'Click Search Result'
  | 'Choose Contact Create Message'
  | 'View Messages Plugin Launch Announcement'
  | 'Click Messages Plugin Launch Announcement Dismiss'
  | 'Click Manage Widget'
  | 'Click Text Us Toggle'
  | 'Update Response Time'
  | 'Click Enable Online Booking from Text Us'
  | 'Select File Attach to Input'
  | 'Click File Virus Modal'
  | 'Click File Oversized Modal'
  | 'Click Sending File Via Email Modal'
  | 'Click File and Photo Sent Separately Modal'
  | 'Click Download Attachment'
  | 'Click File Unsupported Modal'
  | 'Click Into Text Us Multiple Websites';

/**
 * Properties of the tracking. They key must be in snakecase.
 */
export type TrackingProperties = Record<
  string,
  | string
  | number
  | boolean
  | undefined
  | string[]
  | number[]
  | Record<string, unknown>
>;

/**
 * Types of applications that can register tracking events.
 */
export type TrackingApp = 'BLADE' | 'FULL_PAGE_INBOX';

/**
 * Responsible for event tracking.
 */
export default class EventTrackingStore {
  private _stores: MessengerController;

  /**
   * The application that is registering the tracking events (i.e. Messages Blade, Full Page Inbox, etc.)
   */
  private _trackingApp: TrackingApp | undefined;

  private _cdp?: CDP;

  constructor(stores: MessengerController) {
    this._stores = stores;
  }

  /**
   * Initializes the Tracking instance for the application. Initializes the CDP DataAPI.
   * Called once feature flags are loaded, and we know where we're running.
   *
   * @param {string} environment - use a different API key per environment
   * @param {TrackingApp} app - Application that is registering the tracking events.
   */
  init(environment: Environment, app: TrackingApp): void {
    const sqOneTrust = this._stores.services.sqOneTrust.getSqOneTrust();
    const merchantToken = this._stores.user.merchantToken;

    if (sqOneTrust.performanceAndAnalyticsCookiesBlocked) {
      // Do not initialize if analytics cookies are blocked
      Logger.log(
        'Tracking not initialized as performance and analytics cookies are blocked',
      );
    } else if (!window.localStorage) {
      // Do not initialize if localStorage is unavailable
      Logger.log('Tracking not initialized as localStorage is not available');
    } else {
      const isProduction = environment === 'production';
      this._cdp = new CDP({
        apiKey: isProduction ? CDP_PRODUCTION_API_KEY : CDP_STAGING_API_KEY,
        environment: isProduction ? 'production' : 'staging',
      });

      this._cdp.identifyV2({
        entityId: merchantToken,
        entityType: 'merchant',
      });
    }
    this._trackingApp = app;
  }

  /**
   * Record a tracking event.
   *
   * @param {TrackingName} name - the name of the event, as displayed
   * here: https://docs.google.com/spreadsheets/d/1dPLRO4sl3W6m1F41bftmVKV3ueMN2khh6J1xUKCH-5M/edit?ts=5f694813#gid=1026500899
   * @param {TrackingProperties} [properties] - object of event properties when applicable
   */
  track = (name: TrackingName, properties?: TrackingProperties): void => {
    const sqOneTrust = this._stores.services.sqOneTrust.getSqOneTrust();
    // Do not track if analytics cookies are blocked or localStorage is unavailable
    if (
      !sqOneTrust.performanceAndAnalyticsCookiesBlocked &&
      window.localStorage
    ) {
      Logger.log(`Tracking event ${name}`);

      if (!this._cdp) {
        Logger.error('CDP not initialized. Cannot track event.');
        return;
      }

      this._cdp.trackV2({
        eventName: name,
        eventProps: {
          application: 'web',
          platform: 'web',
          referral_applet: 'dashboard',
          tracking_app: this._trackingApp,
          ...properties,
        },
      });
    }
  };
}
