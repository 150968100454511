import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import Logger from './Logger';
import MessengerController from './MessengerController';
import DeveloperTools from './components/DeveloperTools/DeveloperTools';
import MessengerToast from './components/MessengerToast/MessengerToast';
import {
  KEY_ALLOW_DEV_TOOLS,
  KEY_MESSAGES_PLUS,
} from './stores/FeatureFlagStore';
import SelectContactMethodModal from './pages/NewMessagePage/components/SelectContactMethodModal/SelectContactMethodModal';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import MessengerModalFull from './components/MessengerModalFull';
import BusinessNumbersPage from './pages/BusinessNumbersPage/BusinessNumbersPage';
import {
  UnitsToVerifyMessengerPage,
  UnitVerificationFormMessengerPage,
} from './MessengerTypes';
import LinkPluginPage from './pages/LinkPluginPage/LinkPluginPage';
import EditVoicemailPage from 'src/pages/EditVoicemailPage/EditVoicemailPage';
import { ModalFullLayout } from './components/MessengerModalFull/MessengerModalFull';
import MessagesPlusStartVerificationPage from './pages/MessagesPlusStartVerificationPage/MessagesPlusStartVerificationPage';
import UnitsToVerifyPage from 'src/pages/UnitsToVerifyPage/UnitsToVerifyPage';
import UnitVerificationForm from 'src/pages/UnitVerificationForm/UnitVerificationForm';
import UnitVerificationSuccessPage from 'src/pages/UnitVerificationSuccessPage/UnitVerificationSuccessPage';
import MessagesPlusSubscriptionModal from 'src/components/MessagesPlusSubscriptionModal/MessagesPlusSubscriptionModal';
import VerificationFailedDialog from './components/VerificationFailedDialog/VerificationFailedDialog';
import VerificationSuccessDialog from './components/VerificationSuccessDialog/VerificationSuccessDialog';
import SqOnlineSettingsPage from './pages/SqOnlineSettingsPage/SqOnlineSettingsPage';
import MessagesPlusPricingPage from './pages/MessagesPlusPricingPage/MessagesPlusPricingPage';

export type AppOverlaysProps = {
  messenger: MessengerController;
};

/**
 * Renders application level overlays including Sheet and Toast elements.
 *
 * @example <AppOverlays messenger={messenger} />
 * @param {MessengerController} messenger
 * MessengerController instance associated with the Messages React app.
 * @returns {ReactElement}
 */
const AppOverlays = observer(
  ({ messenger }: AppOverlaysProps): ReactElement => {
    const {
      featureFlag,
      navigation,
      user,
      subscription,
      settings,
      status,
      modal,
      tooltip,
    } = messenger;
    /**
     * To allow dev tools, turn it on for this merchant account by adding the merchant token here:
     * https://app.launchdarkly.com/pie/staging/features/messenger-allow-dev-tools/targeting
     *
     * This should strictly be used for testing and debugging purposes, and no real merchants
     * should see this.
     */
    const isDevToolsEnabled =
      Logger.showDevTools && featureFlag.get(KEY_ALLOW_DEV_TOOLS);

    const navigationSheetStore = navigation.sheet;
    let className: string | undefined;
    let layout: ModalFullLayout | undefined;
    let sheetContent;
    switch (navigationSheetStore.currentPageName) {
      case 'SETTINGS':
        sheetContent = <SettingsPage />;
        break;
      case 'MESSAGES_PLUS_PRICING':
        className = 'MessagesPlusPricingPage__modal';
        layout = 'wide';
        sheetContent = <MessagesPlusPricingPage />;
        break;
      case 'BUSINESS_NUMBERS':
        sheetContent = <BusinessNumbersPage />;
        break;
      case 'UNITS_TO_VERIFY': {
        const page =
          navigationSheetStore.currentPage as UnitsToVerifyMessengerPage;
        layout = 'wide';
        sheetContent = <UnitsToVerifyPage unitTokens={page.unitTokens} />;
        break;
      }
      case 'UNIT_VERIFICATION_FORM': {
        const page =
          navigationSheetStore.currentPage as UnitVerificationFormMessengerPage;
        sheetContent = <UnitVerificationForm unitToken={page.unitToken} />;
        break;
      }
      case 'UNIT_VERIFICATION_SUCCESS':
        sheetContent = <UnitVerificationSuccessPage />;
        break;
      case 'LINK_PLUGIN':
        sheetContent = <LinkPluginPage />;
        break;
      case 'EDIT_VOICEMAIL':
        sheetContent = <EditVoicemailPage />;
        break;
      case 'MESSAGES_PLUS_START_VERIFICATION':
        layout = 'wide';
        className = 'MessagesPlusStartVerificationPage__modal';
        sheetContent = <MessagesPlusStartVerificationPage />;
        break;
      case 'SQ_ONLINE_SETTINGS':
        sheetContent = <SqOnlineSettingsPage />;
        break;
      default:
        break;
    }

    return (
      <>
        {isDevToolsEnabled && <DeveloperTools />}
        {navigationSheetStore.isOpen && (
          <MessengerModalFull
            close={
              navigationSheetStore.currentPageName === 'SETTINGS'
                ? settings.close
                : navigation.closeSheet
            }
            layout={layout}
            className={className}
          >
            {sheetContent}
          </MessengerModalFull>
        )}
        <MessengerToast status={status.value} clearStatus={status.clear} />
        {modal.currentModal === 'SELECT_CONTACT_METHOD' && (
          <SelectContactMethodModal />
        )}
        {modal.currentModal === 'MESSAGES_PLUS_SUBSCRIPTION' && (
          <MessagesPlusSubscriptionModal />
        )}
        {featureFlag.get(KEY_MESSAGES_PLUS) &&
          subscription.unitTokensFailedRetryable.length === 1 &&
          user.activeUnits.length === 1 &&
          tooltip.isVisible('VERIFICATION_FAILED_DIALOG') &&
          !navigation.sheet.isOpen &&
          navigation.isFullPageMessenger && <VerificationFailedDialog />}
        {featureFlag.get(KEY_MESSAGES_PLUS) &&
          user.activeUnits.length === 1 &&
          subscription.recentlyVerifiedAndSubscribedNumbers.length === 1 &&
          tooltip.isVisible('VERIFICATION_SUCCESS_DIALOG') &&
          !navigation.sheet.isOpen &&
          navigation.isFullPageMessenger && <VerificationSuccessDialog />}
      </>
    );
  },
);

export default AppOverlays;
